import { useContext, useState } from 'react';

import { t } from '@/i18n/translation_util';
import { Elements, PaymentElement } from '@stripe/react-stripe-js';
import { QueryClientProvider } from '@tanstack/react-query';

import { Button } from '@/components/ui/button';
import {
  defaultTrialDays,
  getPromoFromUrl,
  threeWeekTrialRoutes,
} from '@/lib/promo-codes';
import { cn, stripTrailingSlash } from '@/lib/utils';

import { TooltipProvider } from '@/components/ui/tooltip';
import Spinner from '../../misc/spinner';
import { FormRootMessage } from '../../ui/form';
import { CheckoutForm, LinkEmailStripeInput } from './check-out-form';
import {
  queryClient,
  stripeCheckoutOptions,
  stripePromise,
  useCheckoutStore,
} from './check-out.hooks';
import { PromoCodeInput } from './promo-code-input';

export function CheckoutFormBoxIsland({ className }: { className?: string }) {
  return (
    <QueryClientProvider client={queryClient}>
      <Elements stripe={stripePromise} options={stripeCheckoutOptions}>
        <TooltipProvider>
          <div className={cn('flex flex-col gap-9', className)}>
            <CheckoutForm>
              <LinkEmailStripeInput />
              <PaymentCard />
            </CheckoutForm>
          </div>
        </TooltipProvider>
      </Elements>
    </QueryClientProvider>
  );
}

function PaymentCard() {
  const context = useContext(CheckoutForm.context);
  if (!context) throw new Error('No context');
  const { form, signupAndRegisterMutation } = context;

  const [showPromo, setShowPromo] = useState(() =>
    Boolean(getPromoFromUrl(window.location.pathname)),
  );

  const hidePromoInput = threeWeekTrialRoutes.includes(
    stripTrailingSlash(window.location.pathname),
  );
  const currentPlan = useCheckoutStore((s) => s.currentPlan);
  const planOptions = useCheckoutStore((s) => s.planOptions);
  return (
    <div className="flex flex-col gap-6 rounded-2xl bg-white p-6 shadow-[0_4px_36px_0px_rgba(193,216,251,0.5)]">
      <PaymentElement />
      {!hidePromoInput &&
        (showPromo ? (
          <PromoCodeInput form={form} />
        ) : (
          <div className={cn(`pb-6`)}>
            <Button
              className="bg-transparent p-0 text-base font-medium leading-tight text-[#B2D2FF] hover:bg-transparent hover:underline"
              onClick={() => {
                setShowPromo(true);
              }}
            >
              {t('Have a promo code?')}
            </Button>
          </div>
        ))}
      <FormRootMessage
        data-checkout-error
        className="text-center text-destructive"
      />
      <Button
        data-checkout-submit
        disabled={signupAndRegisterMutation.isPending}
        className={cn(
          'h-auto self-center rounded-full border-b-4 border-[#B2D2FF] px-8 py-3 text-lg font-[500]',
          signupAndRegisterMutation.isPending
            ? 'bg-[#667085] disabled:cursor-not-allowed'
            : 'bg-primary',
        )}
      >
        {signupAndRegisterMutation.isPending && <Spinner className="mr-2" />}
        {t('Try the app for free', { trialDays: defaultTrialDays })}
      </Button>
      {currentPlan.period == 'yearly'
        ? t('You will not be charged for x...paid annually') && (
            <p
              className="text-center text-base font-medium leading-7 text-[#667085]"
              dangerouslySetInnerHTML={{
                __html: t('You will not be charged for x...paid annually', {
                  trialDays: defaultTrialDays,
                  priceYearly: planOptions.yearly.amount,
                  amountSave: (planOptions.yearly.amount / 12).toFixed(2),
                }),
              }}
            />
          )
        : t('You will not be charged for x...per mo') && (
            <p
              className="text-center text-base font-medium leading-7 text-[#667085]"
              dangerouslySetInnerHTML={{
                __html: t('You will not be charged for x...per mo', {
                  trialDays: defaultTrialDays,
                  priceMonthly: currentPlan.amount,
                }),
              }}
            />
          )}
    </div>
  );
}
